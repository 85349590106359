<template>
  <div id="app">
    <div class="fw_box">
      <div class="fw">
        <div v-if="fw[1]">
          <div
            class="fw-firework1"
            :class="'firework-color-' + fw_info[1].color"
          ></div>
          <div class="fw-content">
            <div :class="'firework-text-' + fw_info[1].color">
              <div class="fw-content-text">
                {{ fw_info[1].content }}
              </div>
              <div class="fw-content-author">{{ fw_info[1].author }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="fw">
        <div v-if="fw[0]">
          <div
            class="fw-firework1"
            :class="'firework-color-' + fw_info[0].color"
          ></div>
          <div class="fw-content">
            <div :class="'firework-text-' + fw_info[0].color">
              <div class="fw-content-text">
                {{ fw_info[0].content }}
              </div>
              <div class="fw-content-author">{{ fw_info[0].author }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="fw">
        <div v-if="fw[2]">
          <div
            class="fw-firework1"
            :class="'firework-color-' + fw_info[2].color"
          ></div>
          <div class="fw-content">
            <div :class="'firework-text-' + fw_info[2].color">
              <div class="fw-content-text">
                {{ fw_info[2].content }}
              </div>
              <div class="fw-content-author">{{ fw_info[2].author }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-button_box">
        <div class="footer-button">
          <button class="button" @click="shoot">发射</button>
        </div>
        <div class="footer-button">
          <button class="button2" @click="add">塞烟花</button>
        </div>
      </div>
      <div class="footer-mascot_box">
        <div class="footer-mascot" style="float: left">吉祥物1</div>
        <div class="footer-mascot" style="float: right">吉祥物2</div>
      </div>
      <div class="footer-bottom">
        本网站由<a
          href="https://www.tongstarge.cn"
          target="_blank"
          rel="noopener noreferrer"
          >曈星阁</a
        >制作
      </div>
    </div>
    <s-dialog v-model="q" animation="fliph">
      <div class="fq">烟花已塞入曈星阁的烟花库~</div>
    </s-dialog>
    <s-dialog v-model="j" animation="scale">
      <div class="fj" :class="'fj-' + jx_text.id">{{ jx_text.text }}</div>
    </s-dialog>
    <s-dialog v-model="t" title="塞烟花" animation="slideb">
      <div class="form">
        <div class="form-label">内容：</div>
        <div v-if="c" class="firework-color-explain firework-color-text-0">
          请按要求填写哦！
        </div>
        <s-textarea
          v-model="send_fw.content"
          placeholder="内容在2-15字以内"
          maxlength="50"
          rows="3"
        ></s-textarea>

        <div class="form-label">样式</div>
        <div class="firework-colors">
          <div
            v-for="(f, key) in fireworkColors"
            :key="key"
            class="firework-color_box"
            :class="
              send_fw.color == key ? 'firework-color_box-active-' + key : null
            "
            :style="'width:' + 100 / fireworkColors.length + '%'"
            @click="choice(key)"
          >
            <div class="firework-color" :class="'firework-color-' + key"></div>
            {{ f.name }}
          </div>
        </div>
        <div
          class="firework-color-explain"
          :class="'firework-color-text-' + send_fw.color"
        >
          {{ fireworkColors[send_fw.color].explain }}
        </div>
        <br />
        <div class="form-label">署名：</div>
        <s-input v-model="send_fw.author"></s-input>
      </div>
      <span slot="footer">
        <s-button @click="send" round>塞</s-button>
      </span>
    </s-dialog>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      fw: [false, false, false],
      q: false,
      t: false,
      c: false,
      j: false,
      jx_text: {
        text: "惊喜卡片",
        id: 0,
      },
      fw_info: [
        {
          content: "",
          author: "",
          color: 0,
        },
      ],
      fw_data: [
        {
          content: "希望新的一年可以变更优秀",
          author: "Lai追求",
          color: 1,
        },
        {
          content: "新年快乐！新的一年要更加努力咯~",
          author: "[曈星阁]乐曈",
          color: 0,
        },
        {
          content: "新年新气象",
          author: "[曈星阁]酸纳",
          color: 2,
        },
      ],
      send_fw: {
        content: "",
        author: "",
        color: 0,
      },
      fireworkColors: [
        {
          name: "喜庆红",
          explain: "红色是最喜庆的颜色，象征着新的一年里生活红红火火~",
        },
        {
          name: "愿望橙",
          explain: "据说把愿望写在这种烟花里，愿望在新的一年里将会实现！",
        },
        {
          name: "新彩绿",
          explain: "充满希望的绿色，预示着新的一年风调雨顺、充满活力！",
        },
        {
          name: "七星紫",
          explain: "神秘的紫色带来意想不到的好运气呢~",
        },
      ],
    };
  },
  methods: {
    jx() {
      if (Math.floor(Math.random() * 10) == 1) {
        this.jx_text.text = "惊喜卡片";
        this.jx_text.id = 0;
        this.j = true;
      } else {
        if ((this.fw_info[0] == this.fw_info[1]) == this.fw_info[2]) {
          this.jx_text.text = "天选之子！！！";
          this.jx_text.id = 1;
          this.j = true;
        }
      }
    },
    shoot() {
      for (let i = 0; i < 3; i++) {
        if (this.fw_info[i] && !this.fw[i]) {
          this.$set(this.fw, i, true);
          this.jx();
          setTimeout(() => {
            this.$set(this.fw, i, false);
            this.getfirework(i);
          }, 5000);
          break;
        }
      }
    },
    add() {
      this.t = true;
    },
    send() {
      if (
        this.send_fw.content.length >= 2 &&
        this.send_fw.content.length <= 15
      ) {
        this.t = false;
        this.c = false;
        this.q = true;
        this.fw_data.push(this.send_fw);
        this.send_fw = {
          content: "",
          author: "",
          color: 0,
        };
      } else {
        this.c = true;
      }
    },
    getfirework(i) {
      //获取烟花
      let s = Math.floor(Math.random() * this.fw_data.length);
      this.$set(this.fw_info, i, this.fw_data[s]);
    },
    choice(key) {
      this.$set(this.send_fw, "color", key);
    },
  },
  created() {
    for (let i = 0; i < 3; i++) {
      this.getfirework(i);
    }
  },
};
</script>

<style>
@import url(./style/global.css);
* {
  margin: 0;
  padding: 0;
  user-select: none;
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #00000011;
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #00000046;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: #071c2c;
}
.footer {
  position: fixed;
  width: 100vw;
  height: 200px;
  background-color: rgb(177, 107, 50);
  bottom: 0;
  left: 0;
}
.footer-button_box {
  position: absolute;
  width: 100%;
  top: -50px;
}
.footer-button {
  margin: 20px auto;
  text-align: center;
}
.button {
  cursor: pointer;
  background-color: #ff4c00;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 900;
  box-shadow: 0 0 0 5px #ff4d0080;
  display: inline-block;
  transition: 0.3s ease-in-out;
  z-index: 100;
  animation: a1 1s ease-in-out infinite;
  border: none;
}
.button:hover {
  transform: scale(1.05);
}
.button:active {
  transform: scale(0.95);
}
@keyframes a1 {
  0% {
    box-shadow: 0 0 0 5px #ff4d0080;
  }
  50% {
    box-shadow: 0 0 0 5px #ff4d0044;
  }
  100% {
    box-shadow: 0 0 0 5px #ff4d0080;
  }
}
.button2 {
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  font-size: 15px;
  color: #f0f0f0;
  display: inline-block;
  transition: 0.3s ease-in-out;
  background: none;
  z-index: 100;
  border: none;
}
.button2:hover {
  color: #d4d4d4;
}
.button2:active {
  transform: scale(0.95);
}
.footer-mascot_box {
  width: 600px;
  margin: auto;
  margin-top: -50px;
}
.footer-mascot {
  background-color: rgb(235, 133, 0);
  height: 200px;
  width: 200px;
}
.footer-bottom {
  background-color: #333333;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  height: 30px;
}
.fw_box {
  display: flex;
  justify-content: center;
}
.fw {
  width: 30%;
  height: 100vh;
  position: relative;
}
.fw-firework1 {
  position: absolute;
  width: 2px;
  height: 50px;
  left: 50%;
  margin-left: -1px;
  opacity: 0;
  animation: f1 0.5s ease-out;
  background-color: #ff0000;
}
.fw-content {
  width: 100%;
  height: 200px;
  margin: 5vh auto;
  animation: f2 5s ease-in-out;
  background-position: center;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fw-content-text {
  margin-top: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: 900;
  opacity: 0.9;
  animation: f3 5s ease-in-out;
}
.fw-content-author {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  animation: f3 5s ease-in-out;
  opacity: 0.6;
}
@keyframes f1 {
  0% {
    opacity: 0;
    top: 100vh;
    margin-top: -50px;
  }
  100% {
    opacity: 1;
    top: 20vh;
  }
}
@keyframes f2 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  10% {
    opacity: 1;
    transform: scale(0);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    transform: translateY(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(50px) scale(1.05);
  }
}
@keyframes f3 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    transform: translateY(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(50px) scale(1.05);
  }
}
.s-dialog-box {
  background-color: #ff4c00 !important;
}
.s-dialog-content {
  margin: 20px;
  padding: 0 !important;
  background-color: #ffffff;
  border-radius: 10px;
}
.form {
  margin: 20px;
}
.form .s-input {
  width: 100%;
  margin: 10px 0;
}
.form .s-textarea {
  width: 100%;
  margin: 10px 0;
}
.form-label {
  padding: 10px 0;
  font-size: 18px;
}
.footer {
  color: #ffffffbb;
}
.footer a {
  color: #ff4c0088;
  text-decoration: none;
  padding: 0 5px;
  transition: 0.2s;
}
.footer a:hover {
  color: #ff4c00;
}
.firework-colors {
  display: flex;
}
.firework-color_box {
  background: #f3efee;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s;
  font-size: 13px;
  line-height: 20px;
}
.firework-color_box:hover {
  background: #d6d4d3;
}
.firework-color_box-active-0 {
  background: #ffb0b0 !important;
  box-shadow: 0 0 0 5px #ff000022;
}
.firework-color_box-active-1 {
  background: #ffd595 !important;
  box-shadow: 0 0 0 5px #ff990022;
}
.firework-color_box-active-2 {
  background: #adfcc2 !important;
  box-shadow: 0 0 0 5px #9bffb655;
}
.firework-color_box-active-3 {
  background: #bda5ff !important;
  box-shadow: 0 0 0 5px #6334e722;
}
.firework-color {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.firework-color-explain {
  font-size: 12px;
}
.firework-color-0 {
  background-color: #ff0000;
}
.firework-color-text-0 {
  color: #ff0000;
}
.firework-text-0 {
  color: #ff0000;
  text-shadow: 0 0 5px#ff0000;
}
.firework-color-1 {
  background-color: #ff9900;
}
.firework-color-text-1 {
  color: #ff9900;
}
.firework-text-1 {
  color: #ff9900;
  text-shadow: 0 0 5px#ff9900;
}
.firework-color-2 {
  background-color: #afdd22;
  background-image: linear-gradient(250deg, #1bd1a5, #afdd22 80%);
}
.firework-color-text-2 {
  color: #afdd22;
}
.firework-text-2 {
  color: #afdd22;
  text-shadow: 0 0 5px#afdd22;
}
.firework-color-3 {
  background-color: #6334e7;
  background-image: linear-gradient(250deg, #8834e7, #6334e7 60%, #3a2a94);
}
.firework-color-text-3 {
  color: #6334e7;
}
.firework-text-3 {
  color: #6334e7;
  text-shadow: 0 0 5px#6334e7;
}
@media screen and (max-width: 800px) {
  .firework-color_box {
    color: #00000000 !important;
    white-space: nowrap;
    overflow: hidden;
  }
}
.fq {
  width: 100%;
  height: 100%;
  background: #ff4c00;
  color: #ffffff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fj {
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #ffffff;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}
.fj-0 {
  background-image: linear-gradient(66deg, #a76c6c, #7a66d4);
  animation: fj0 1s ease-out;
}
@keyframes fj0 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.fj-1 {
  background-image: linear-gradient(220deg, #ff0000, #4a26e7);
}
</style>
